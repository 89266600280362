import React, { useState } from "react";

const testimonialsData = [
  {
    id: 1,
    name: "John Doe",
    role: "CEO, ABC Inc.",
    content:
      "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.",
    initials: "JD",
  },
  {
    id: 2,
    name: "Winter Doe",
    role: "CTO, XYZ Corp.",
    content:
      "Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.",
    initials: "WD",
  },
  {
    id: 3,
    name: "John Wick",
    role: "Product Manager, Fake Corp.",
    content:
      "Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.",
    initials: "JW",
  },
];

const Testimonial = () => {
  const [testimonialActive, setTestimonialActive] = useState(2);

  const handlePrevClick = () => {
    setTestimonialActive((prev) => (prev === 1 ? testimonialsData.length : prev - 1));
  };

  const handleNextClick = () => {
    setTestimonialActive((prev) => (prev >= testimonialsData.length ? 1 : prev + 1));
  };

  return (
    <div className="my-10 tablet:my-24 p-6 mx-auto flex flex-col tablet:flex-row rounded-2xl overflow-hidden">
      <div className="relative bg-gradient-to-r from-[#1a337f] to-sky-400 w-full py-2 rounded-tl-2xl rounded-bl-2xl tablet:py-24 tablet:w-1/2 flex flex-col justify-center">
        <div className="relative text-2xl tablet:text-5xl py-2 px-6 tablet:w-64 tablet:mx-auto text-blue-100 font-semibold leading-tight tracking-tight mb-0">
          <span className="tablet:block">What Our</span>
          <span className="md-block">Customers</span>
          <span className="block">Are Saying!</span>
        </div>
        <div className="absolute right-0 bottom-0 mr-4 mb-4 hidden tablet:block">
          <button
            className="rounded-l-full bg-gray-100 text-gray-500 hover:text-[#1a337f] font-bold w-12 h-10"
            onClick={handlePrevClick}
          >
            &#8592;
          </button>
          <button
            className="rounded-r-full bg-gray-100 text-gray-500 hover:text-[#1a337f] font-bold w-12 h-10"
            onClick={handleNextClick}
          >
            &#8594;
          </button>
        </div>
      </div>

      <div className="bg-gray-100 tablet:w-1/2 rounded-tr-2xl rounded-br-2xl flex flex-col justify-center items-center">
        {testimonialsData.map((testimonial) => (
          <div key={testimonial.id} style={{ display: testimonialActive === testimonial.id ? "block" : "none" }}>
            <p className="text-gray-600 italic px-6 py-6 tablet:px-16 tablet:py-10 text-xl tablet:text-2xl">
              {testimonial.content}
            </p>
            <div className="text-center">
              <h2 className="text-sm tablet:text-base font-bold text-gray-700">
                {testimonial.name}
              </h2>
              <small className="text-gray-500 text-xs tablet:text-sm">
                {testimonial.role}
              </small>
            </div>
          </div>
        ))}
        <div className="flex my-4">
          {testimonialsData.map((testimonial) => (
            <button
              key={testimonial.id}
              onClick={() => setTestimonialActive(testimonial.id)}
              className={`rounded-full mx-2 font-bold focus:outline-none ${
                testimonialActive === testimonial.id
                  ? "h-16 w-16 bg-[#1a337f] text-white"
                  : "h-12 w-12 bg-blue-300/50 text-[#1a337f]"
              }`}
            >
              {testimonial.initials}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
