import React, { useState } from "react";

import img1 from "../../Assets/im1.jpg";
import img2 from "../../Assets/im2.jpg";
import img3 from "../../Assets/im3.jpg";
import img4 from "../../Assets/im4.jpg";
import img5 from "../../Assets/im5.jpg";
import img6 from "../../Assets/img6.jpg";
import img7 from "../../Assets/img7.jpg";
import img8 from "../../Assets/img8.jpg";
import img9 from "../../Assets/img9.jpg";
import img10 from "../../Assets/img10.jpg";
import img11 from "../../Assets/img11.jpg";

const images = [
  { src: img1, title: "Science Workshop" },
  { src: img2, title: "Tech Exploration" },
  { src: img3, title: "Engineering Wonders" },
  { src: img4, title: "Arts Integration" },
  { src: img5, title: "Mathematics Challenges" },
  { src: img6, title: "Coding Bootcamp" },
  { src: img7, title: "Innovation Hub" },
  { src: img8, title: "Design Thinking" },
  { src: img9, title: "Automation Lab" },
  { src: img10, title: "Creative Projects" },
  { src: img11, title: "Future Tech" },
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className="gallery-container p-8 bg-gray-100">
      <h2 className="text-5xl font-extrabold text-center mb-10 text-[#1e337f]">Gallery Showcase</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 tablet:grid-cols-4 gap-8">
        {images.map((image, index) => (
          <div key={index} className="relative cursor-pointer rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition-all duration-300" onClick={() => setSelectedImage(image)}>
            <img src={image.src} alt={image.title} className="w-full h-72 object-cover" />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white text-lg font-medium p-4">
              {image.title}
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="relative max-w-4xl w-full p-6 bg-white rounded-xl shadow-2xl transition-all transform scale-95 hover:scale-100">
            <button
              className="absolute top-4 right-4 bg-red-500 text-white rounded-full p-3 hover:bg-red-600 transition-all"
              onClick={() => setSelectedImage(null)}
            >
              ✕
            </button>
            <img src={selectedImage.src} alt={selectedImage.title} className="w-full h-auto rounded-lg shadow-md" />
            <h3 className="text-center text-[#1e337f] mt-6 text-3xl font-semibold">{selectedImage.title}</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
