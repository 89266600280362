import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TranslateIcon from "@mui/icons-material/Translate";

const Update = ({ items, lang, setLang }) => {
  return (
    <div className="flex justify-center">
      <div className="relative border border-gray-300  rounded-md w-[480px] bg-white overflow-hidden">
        
        {/* Language toggle button */}
        <button
          onClick={() => setLang(lang === "en" ? "hi" : "en")}
          className="absolute top-3 right-3 bg-[#627bc4] text-white px-4 py-2 rounded-md text-sm flex items-center gap-2 shadow-md transition-transform hover:scale-105"
        >
          {/* <TranslateIcon style={{ fontSize: "18px" }} /> */}
          {lang === "en" ? "हिन्दी" : "English"}
        </button>

        {/* Header */}
        <h1 className="bg-[#627bc4] py-4 px-6 text-white font-bold text-lg flex items-center gap-2 shadow-md">
          <StarBorderIcon style={{ color: "white" }} />
          {lang === "en" ? "What's New" : "नया क्या है?"}
        </h1>

        {/* Announcements List */}
        <ul className="p-4 max-h-[350px] overflow-y-auto custom-scrollbar">
          {items.length > 0 ? (
            items.map((item, index) => (
              <li
                key={index}
                className={`p-4 rounded-lg mb-3 ${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                }`}
              >
                <p className="text-gray-600 flex items-center gap-2">
                  <CalendarMonthIcon style={{ fontSize: "16px" }} /> {item.date}
                </p>
                <p className="text-gray-800 text-sm mt-1">{item.content}</p>
              </li>
            ))
          ) : (
            <p className="text-center text-gray-600 font-semibold">
              {lang === "en" ? "No announcements for now." : "अभी कोई घोषणा नहीं है।"}
            </p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Update;
