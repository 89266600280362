import React, { useEffect } from "react";
import "./About.css";
import CEOImg from "../../Assets/ceo.png"
import CofoundImg from "../../Assets/cofound1.png"
import CofoundImg2 from "../../Assets/cofound2.png"
import Missionbg from "../../Assets/mission bg.jpg";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import LocalLibrarySharpIcon from "@mui/icons-material/LocalLibrarySharp";
import RadarRoundedIcon from "@mui/icons-material/RadarRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import BG from '../../Assets/bgblue.png'
import AOS from "aos";
import "aos/dist/aos.css";
import Faq from "./Faq";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
      easing: "ease-in-out",
    });

  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col h-auto border-0  border-black">
      {/* welcome section  */}

      <div className="parallax-wrapper">
      <section className="section_1 relative">
        {/* Background Image */}
        <div className="absolute inset-0 z-[-1]">
          <img
            src={BG}
            // src="https://t4.ftcdn.net/jpg/05/74/77/61/360_F_574776101_7XOaFUlqGOfWTYQxBg3d10Qvp6jI3gba.jpg"
            alt="Background"
            className="object-cover w-full h-full fixed inset-0"
          />
        </div>

        {/* Content */}
        <div className="section_2 relative  flex flex-col justify-center items-center tablet:h-[70vh] mobiles:h-[40vh]">
          <div className="laptop:text-7xl mobiles:text-3xl font-bold  roboto text-center p-9  text-white">
            Welcome to Science-Sarathi
          </div>
          <div className="text-3xl w-[90%] text-white roboto text-center">
            <p>
              Science-Sarathi is at the forefront of revolutionizing education by
              seamlessly integrating Robotics, Programming, Artificial
              Intelligence, and Mechatronics.
            </p>
          </div>
        </div>
      </section>
    </div>
      {/* <hr className="shadow-3xl h-1 shadow-slate-400" /> */}

      {/* vission  */}
      <section className="border-0 border-red-800 bg-white  tablet:p-4 mobiles:p-2 h-auto flex gap-4 justify-center tablet:mt-16 mobiles:mt-6 flex-wrap">
        <div
          className="laptop:w-[700px] mobiles:w-full border-0 border-green-800 tablet:flex  flex-col justify-end items-center"
          // data-aos="zoom-in-up"
          // data-aos-duration="1500"
        >
          <img
            src={Missionbg}
            alt="robot"
            className="laptop:w-[320px] laptop:h-[320px] mobiles:w-[50%] mobiles:h-[90%] flip rounded-xl shadow-inner mx-auto shadow-slate-300"
          />
        </div>
        <div
          className="laptop:w-[750px]  border-0 border-black flex justify-around flex-col mobiles:items-end tablet:items-start"
          // data-aos="zoom-in-down"
          // data-aos-duration="1000"
        >
          <div className="w-[90%] h-full border-0 border-green-700 flex justify-around flex-col items-start roboto">
            <h1 className="laptop:text-[32px] mobiles:text-[32px] leading-9 text-[#040f26]  p-2 font-semibold drop-shadow-lg">
              Our Mission
            </h1>
            <h1 className="text-[18px] roboto p-2 font-bold ">
              Our mission is simple yet profound:
            </h1>
            <p className="text-[18px] w-[80%] text[#040f26] leading-8 roboto p-2 ">
            <ul className="custom-list roboto text-justify	">
        <li>Igniting curiosity through immersive, inclusive robotics experiences,</li>
        <li>To foster exploration and hands-on learning.</li>
        <li>We are dedicated to cultivating critical skills like collaboration, communication, and adaptability.</li>
        <li>Empowering students from diverse backgrounds to become future innovators and problem solvers.</li>
      </ul>
            </p>
            <button className="btnhover"> Know More..</button>
          </div>
        </div>
      </section>

      {/* why choose us  */}
      <section
        className="flex flex-col p-6 justify-center bg-white items-center h-auto"
        // data-aos="fade-up"
        // data-aos-anchor-placement="top-center"
      >
        <h1 className="laptop:text-[32px] mobiles:text-[32px] leading-9 text-[#040f26]  p-2 font-semibold drop-shadow-lg">
          {" "}
          Why choose us ?
        </h1>
        <div className="border-0 border-red-700 w-full roboto h-auto flex p-8 justify-center items-center flex-col">
          <div className="border-0 border-green-600 h-auto tablet:w-[70vw] flex  justify-center gap-3 flex-wrap">
            {/* Card  */}
            <div className="w-[350px] text-justify hover:scale-105 ease-in-out duration-700 h-auto border-2 flex flex-col  justify-between  bg-white hover:bg-sky-200 rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
              <div className="icon  w-full h-auto flex justify-start p-2">
                <div className="w-[30px] h-auto rounded-md border-0 border-red-500">
                  {" "}
                  <SchoolSharpIcon style={{ color: "gray" }} />{" "}
                </div>{" "}
              </div>

              <p className="p-4 font-semibold">
                We are the team of experts form IITs, NITs, and Institute of
                National Importance
              </p>
            </div>
            {/* Card end  */}

            {/* Card  */}
            <div className="w-[350px] hover:scale-105 ease-in-out duration-700 h-auto border-2 flex flex-col  justify-between  bg-white hover:bg-orange-200 rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
              <div className="icon  w-full h-auto flex justify-start p-2">
                <div className="w-[30px] h-auto rounded-md border-0 border-red-500">
                  {" "}
                  <LocalLibrarySharpIcon style={{ color: "gray" }} />{" "}
                </div>{" "}
              </div>

              <p className="p-4 font-semibold">
                Our Adaptive Learning Platforms provide individual attention to
                every single child.
              </p>
            </div>
            {/* Card end  */}

            {/* Card  */}
            <div className="w-[350px] hover:scale-105 ease-in-out duration-700 h-auto border-2 flex flex-col  justify-between  bg-white hover:bg-sky-200 rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
              <div className="icon  w-full h-auto flex justify-start p-2">
                <div className="w-[30px] h-auto rounded-md border-0 border-red-500">
                  {" "}
                  <RadarRoundedIcon style={{ color: "gray" }} />{" "}
                </div>{" "}
              </div>

              <p className="p-4 font-semibold">
                Our courses provide holistic development of child which lead to
                carrier-oriented growth
              </p>
            </div>
            {/* Card end  */}

            {/* Card  */}
            <div className="w-[350px] hover:scale-105 ease-in-out duration-700 h-auto border-2 flex flex-col  justify-between  bg-white hover:bg-orange-200 rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
              <div className="icon  w-full h-auto flex justify-start p-2">
                <div className="w-[30px] h-auto rounded-md border-0 border-red-500">
                  {" "}
                  <DevicesRoundedIcon style={{ color: "gray" }} />{" "}
                </div>{" "}
              </div>

              <p className="p-4 font-semibold">
                Elevated Learning Experiences Through Seamless Technological
                Integration
              </p>
            </div>
            {/* Card end  */}

            {/* Card  */}
            <div className="w-[350px] hover:scale-105 ease-in-out duration-700 h-auto border-2 flex flex-col  justify-between  bg-white hover:bg-sky-200 rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
              <div className="icon  w-full h-auto flex justify-start p-2">
                <div className="w-[30px] h-auto rounded-md border-0 border-red-500">
                  {" "}
                  <PrecisionManufacturingIcon style={{ color: "gray" }} />{" "}
                </div>{" "}
              </div>

              <p className="p-4 font-semibold">
                We focus more on hand on experiments instead of teaching
                theories.
              </p>
            </div>
            {/* Card end  */}
          </div>
        </div>
      </section>

      {/* Out services */}
      <section
        className="border-0 background-services border-red-600 w-full h-auto roboto"
        // data-aos="fade-up"
        // data-aos-anchor-placement="top-center"
      >
        <div className="flex flex-col justify-center items-center gap-3">
          <h1 className="border-0 border-red-700 w-auto h-auto laptop:text-[32px] mobiles:text-[32px] font-semibold">
            Our Services
          </h1>
          <h3 className="border-0 border-red-700 w-auto h-auto text-[18px]">
            Best in the Industary
          </h3>
        </div>
        <div className="timeline">
          <div className="container left-container" data-aos="flip-left">
            <img src="https://color-hex.org/colors/3b82f6.png" alt="" />
            <div className="text-box">
              <h2>Short Term Courses</h2>
             
              <p>
                Elevate your skills with our diverse range of short-term courses
                designed to provide quick and targeted knowledge boosts,
                ensuring you stay ahead in your field.
              </p>
              <span className="left-container-arrow"></span>
            </div>
          </div>

          <div className="container right-container" data-aos="flip-right">
            <img src="https://color-hex.org/colors/3b82f6.png" alt="" />
            <div className="text-box">
              <h2>Classroom Programs</h2>
             
              <p>
                Immerse yourself in interactive and engaging classroom programs,
                led by experienced instructors, fostering a conducive
                environment for collaborative learning and skill development.
              </p>
              <span className="right-container-arrow"></span>
            </div>
          </div>

          <div className="container left-container" data-aos="flip-left">
            <img src="https://color-hex.org/colors/3b82f6.png" alt="" />
            <div className="text-box">
              <h2>Training Programs</h2>
            
              <p>
                Tailored training programs that cater to your specific needs,
                equipping you with the essential tools and knowledge for
                personal and professional growth
              </p>
              <span className="left-container-arrow"></span>
            </div>
          </div>

          <div className="container right-container" data-aos="flip-right">
            <img src="https://color-hex.org/colors/3b82f6.png" alt="" />
            <div className="text-box">
              <h2>Workshops</h2>
             
              <p>
                Participate in hands-on workshops crafted to enhance your
                practical skills and provide valuable insights, creating a
                dynamic learning experience.
              </p>
              <span className="right-container-arrow"></span>
            </div>
          </div>

          <div className="container left-container" data-aos="flip-left">
            <img src="https://color-hex.org/colors/3b82f6.png" alt="" />
            <div className="text-box">
              <h2>Seminars</h2>
            
              <p>
                Engage in thought-provoking seminars led by industry experts,
                offering a platform for in-depth discussions on current trends,
                challenges, and advancements in various domains.
              </p>
              <span className="left-container-arrow"></span>
            </div>
          </div>

          <div className="container right-container" data-aos="flip-right">
            <img src="https://color-hex.org/colors/3b82f6.png" alt="" />
            <div className="text-box">
              <h2>Robotics Lab Set Up</h2>
              
              <p>
                Empower innovation with our comprehensive Robotics Lab Set Up
                services, providing the infrastructure and expertise needed to
                establish cutting-edge robotics facilities for research and
                development.
              </p>
              <span className="right-container-arrow"></span>
            </div>
          </div>
        </div>
      </section>

      {/* Team section  */}

      <section className="border-0 border-green-800 bg-white w-full h-auto roboto flex flex-col justify-evenly items-center">
        <div class="font-[sans-serif] text-[#333] ">
          <div class="tablet:max-w-7xl max-w-2xl mx-auto p-4">
            <h2 class="text-3xl font-extrabold text-center">Meet Our Team</h2>
            <h3 className="border-0 border-red-700 w-auto h-auto text-[18px] text-center">
              Industry Experts And Professionals
            </h3> 
            <div class="grid tablet:grid-cols-2 grid-cols-1 gap-8 max-monilel:justify-center mt-12">
            <div class="flex items-center max-mobiles:flex-col">
                <img
                  src=""
                  alt=""
                  class="w-full mobiles:h-60 rounded object-contain object-top"
                />
                <div class="p-4 mobiles:ml-4">
                  <h4 class="text-base font-extrabold">Dr.Santosh kr. Singh</h4>
                  <p class="text-xs mt-1">(Ph.D., M.Tech, IIT Dhanbad)</p>
                  <div class="mt-4">
                    <p class="text-sm leading-relaxed">
                    Ex- 10+ yrs (Teaching & Research ) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </div>
                  <div class="space-x-4 mt-4">
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-blue-600 hover:bg-blue-700 active:bg-blue-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 155.139 155.139"
                      >
                        <path
                          d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"
                          data-original="#010002"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-[#03a9f4] hover:bg-[#03a1f4] active:bg-[#03a9f4]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
                          data-original="#03a9f4"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-[#0077b5] hover:bg-[#0055b5] active:bg-[#0077b5]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 0 0 2.882 0z"
                          data-original="#0077b5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex items-center max-mobiles:flex-col">
                <img
                  src={CEOImg}
                  alt=""
                  class="w-full mobiles:h-60 rounded object-contain object-top"
                />
                <div class="p-4 mobiles:ml-4">
                  <h4 class="text-base font-extrabold">Mr.Amarendra Kumar</h4>
                  <p class="text-xs mt-1">(M.Tech, IIEST Shibpur)</p>
                  <div class="mt-4">
                    <p class="text-sm leading-relaxed">
                    Ex- 5+ yrs (Teaching, Research & Industry) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </div>
                  <div class="space-x-4 mt-4">
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-blue-600 hover:bg-blue-700 active:bg-blue-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 155.139 155.139"
                      >
                        <path
                          d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"
                          data-original="#010002"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-[#03a9f4] hover:bg-[#03a1f4] active:bg-[#03a9f4]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
                          data-original="#03a9f4"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-[#0077b5] hover:bg-[#0055b5] active:bg-[#0077b5]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 0 0 2.882 0z"
                          data-original="#0077b5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
             
              <div class="flex items-center max-mobiles:flex-col">
                <img
                  src={CofoundImg2}
                  alt=""
                  class="w-full mobiles:h-60 rounded object-contain object-top"
                />
                <div class="p-4 mobiles:ml-4">
                  <h4 class="text-base font-extrabold">Mrs.Purnima Tigga</h4>
                  <p class="text-xs mt-1">(M.Tech, IIT Dhanbad)</p>
                  <div class="mt-4">
                    <p class="text-sm leading-relaxed">
                    Ex- 3+ yrs (Teaching & Research) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </div>
                  <div class="space-x-4 mt-4">
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-blue-600 hover:bg-blue-700 active:bg-blue-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 155.139 155.139"
                      >
                        <path
                          d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z"
                          data-original="#010002"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-[#03a9f4] hover:bg-[#03a1f4] active:bg-[#03a9f4]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
                          data-original="#03a9f4"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="w-7 h-7 inline-flex items-center max-mobiles:flex-col justify-center rounded-full border-none outline-none bg-[#0077b5] hover:bg-[#0055b5] active:bg-[#0077b5]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        fill="#fff"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 0 0 2.882 0z"
                          data-original="#0077b5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <section className="border-0 border-green-800 w-full h-auto roboto flex flex-col justify-evenly items-center">
        <Faq/>
      </section>
    </div>
  );
};

export default About;
