import React, { useEffect, useState } from "react";
import "./Home.css";
import Hero from "../HeroSection/Hero";
import AB from "../../Assets/ab.png";
import Update from "./Update";
import CarouselIndicatorsControlsInside from "../Courses/CarouselControlsInside";
import Testimonial from "../Testimonial/Testimonial";

const Home = () => {
  const [itemsEn, setItemsEn] = useState([]);
  const [itemsHi, setItemsHi] = useState([]);
  const [lang, setLang] = useState("en");

  useEffect(() => {
    const jsonData = [
      {
        id: "Dec 05 2024",
        date: "Dec 5th 2024",
        title: "New Internship Programs Announced!",
        content:
          "We are excited to announce the launch of two comprehensive internship programs for B.Tech and diploma students, focusing on Python and Embedded Systems & IoT. For more details about the course please visit.",
      },
      {
        id: "Dec 11 2023",
        date: "Jan 26th 2024",
        title: "Exciting News!",
        content:
          "An internship program on A.I, Python, and Robotics is going to start from 11th Jan 2024 for the students of Navodaya Vidyalayas, Banka, Bihar.",
      },
    ];

    const jsonDataHi = [
      {
        id: "Dec 05 2024",
        date: "Dec 5th 2024",
        title: "नए इंटर्नशिप कार्यक्रमों की घोषणा!",
        content:
          "हमें यह घोषणा करते हुए खुशी हो रही है कि बी.टेक और डिप्लोमा छात्रों के लिए पायथन और एम्बेडेड सिस्टम्स एवं IoT पर दो व्यापक इंटर्नशिप कार्यक्रम शुरू किए जा रहे हैं। अधिक जानकारी के लिए कृपया पाठ्यक्रम विवरण देखें।",
      },
      {
        id: "Dec 11 2023",
        date: "Jan 26th 2024",
        title: "रोमांचक समाचार!",
        content:
          "नवोदय विद्यालय, बांका, बिहार के छात्रों के लिए ए.आई, पायथन और रोबोटिक्स पर एक इंटर्नशिप कार्यक्रम 11 जनवरी 2024 से शुरू होने जा रहा है।",
      },
      
    ];

    setItemsEn(jsonData);
    setItemsHi(jsonDataHi);
  }, []);

  return (
    <div className="h-auto w-full border-0 border-red-800">
      <Hero />

      <section className="border-0 border-red-800 tablet:p-4 mobiles:p-2 h-auto flex gap-4 justify-center flex-row tablet:mt-16 mobiles:mt-6 flex-wrap">
        <div className="laptop:w-[700px] mobiles:w-full border-0 border-green-800 tablet:flex  flex-col justify-end items-center">
        <Update items={lang === "en" ? itemsEn : itemsHi} lang={lang} setLang={setLang} align="center" />
        </div>
        <div className="laptop:w-[750px] border-0 border-black flex justify-around flex-col mobiles:items-center tablet:items-start">
          <div className="w-[90%] h-full border-0 border-green-700 flex justify-around flex-col items-start roboto">
            <h1 className="laptop:text-[32px] mobiles:text-[32px] leading-9 text-[#040f26] p-2 font-semibold drop-shadow-lg">
              Transforming Education In The Dynamic Landscape
            </h1>
            <p className="text-[18px] w-[80%] text-justify text-[#36454F] leading-8 roboto p-2">
              Science-Sarathi leads the educational revolution, seamlessly
              integrating Robotics, Programming, AI, and Mechatronics.
              Committed to excellence, we provide adaptive solutions fostering
              21st-century skills—critical thinking, creativity, communication,
              and collaboration. Empowering students with transformative
              opportunities beyond classrooms, we prepare them for a future
              shaped by cutting-edge technologies.
            </p>
            <button className="btnhover"> Know More..</button>
          </div>
        </div>
      </section>

      {/* <section className="border-0 p-4 border-red-600 w-full h-auto">
        <div className="flex flex-col justify-center items-center">
          <div className="tablet:text-[40px] mobiles:text-[35px] border-0 border-black w-full text-center text-black p-7 background-update font-bold leading-10 roboto">
            <span className="bg-white w-auto tablet:p-4">
              Recent Updates on Science Sarathi
            </span>
          </div>
          <h1 className="text-[16px] text-black pb-6 font-bold">
            Scroll down to check out our latest articles
          </h1>

          <div className="h-auto w-full border-0 mt-10 border-red-600 flex justify-center items-center">
            <Update items={lang === "en" ? itemsEn : itemsHi} lang={lang} setLang={setLang} align="center" />
          </div>
        </div>
      </section> */}

      <section className="h-auto pb-6">
        <div className="text-[40px] mt-20 border-0 border-black w-full text-center text-black p-7 font-bold roboto">
          <span className="bg-transparent w-auto p-4"> Well Formated Classes </span>
          <h1 className="text-[16px] text-black pb-6 font-bold">1000+ Students believe</h1>
        </div>
        <div className="w-[80%] mx-auto pt-4">
          <CarouselIndicatorsControlsInside />
        </div>
      </section>

      {/* <section className="h-auto pb-6">
        <Testimonial/>
      </section> */}


    </div>
  );
};

export default Home;
