import React from "react";
import "./Hero.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate('/workshop');
  };

  return (
    <section className="relative flex flex-wrap items-center justify-start text-left h-screen backgr-hero text-white">
      <div className="max-w-3xl p-8 ml-12">
        <h2 className="text-5xl sm:text-6xl font-extrabold leading-tight">
          <span className="text-[#f1ab49]">STEAM LAB</span>
          <br />
          <span className="text-white text-6xl sm:text-7xl font-light">Robotics Revolution</span>
        </h2>
        <p className="mt-6 text-lg sm:text-xl font-light leading-relaxed">
          Fusing Science, Technology, Engineering, Arts & Maths
        </p>
        <button
          className="mt-10 px-8 py-4 bg-[#f1ab49] text-[#1e337f] rounded-full font-semibold text-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300"
          onClick={handleBookDemoClick}
        >
          Book Free Demo
        </button>
      </div>
    </section>
  );
};

export default Hero;
